import React, { useContext, useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { navigate } from 'gatsby'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Box from '../../components/box'
import { AuthProvider, AuthContext } from '../../context/auth'
import config from '../../utils/config'
import checkUtmParams from '../../utils/checkUtmParams'
import debounce from '../../utils/debounce'
import Input from '../../components/input'
import {
  AuthTitle,
  Button,
  Container,
  CopySubText,
  ErrorBanner,
  MobileAuthContainer,
} from '.'
import WorkspaceSetupSidebar from '../../components/workspaceSetupSidebar'
import axios from 'axios'
import useOnEnterEventListener from '../../utils/hooks/useOnEnterEventListener'
import handleAuthNetworkError from '../../utils/handleAuthNetworkError'
import useHotjarScript from '../../utils/hooks/useHotjarScript'
import BasicSelect from '../../components/select/basicSelect'
import AuthSidebar from '../../components/authSidebar'
import { isMobileView } from '../../utils/isMobileView'
import MobileTopBar from '../../components/mobileTopBar'

const ClaimWorkspaceContainer = () => (
  <AuthProvider>
    <ClaimWorkspace />
  </AuthProvider>
)

const FormBox = styled.div`
  /* margin-top: 234px; */
  width: 100%;
  display: flex;
  justify-content: center;
  @media (max-width: 769px) {
    margin-top: 0px;
  }
`

const PulseDomain = styled.div`
  width: 108px;
  /* height: 46px; */
  background-color: #fafafa;
  padding: 8px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* font-weight: 600; */
  font-size: 16px;
  line-height: 28px;
  color: #757575;
  border-top: 1px solid rgba(0, 0, 0, 0.08);
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  border-right: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 0px 5px 5px 0px;
  box-sizing: border-box;
  @media (max-width: 769px) {
    width: 100%;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 28px;
  }
`

const Header = styled.div`
  font-family: ${props => props.theme.typography.fontFamilyGT};
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
`

// const Input = styled.input`
//   width: 260px;
//   height: 48px;
//   max-width: 90vw;
//   box-sizing: border-box;
//   background: ${props => props.theme.core.background.tertiary};
//   font-size: 16px;
//   line-height: 28px;
//   padding: ${props =>
//     `${props.theme.spacing(1.75)} ${props.theme.spacing(2.5)}`};
//   padding-right: 43px;
//   border: 2px solid transparent;
//   outline: none;
//   transition: all 100ms ease-in-out;
//   font-family: ${props => props.theme.typography.fontFamilyGTMono};
//   :focus {
//     background: ${props => props.theme.colors.white};
//     border: 2px solid ${props => props.theme.colors.black};
//   }
// `

const InputLabel = styled.label`
  position: absolute;
  right: 16px;
  font-family: ${props => props.theme.typography.fontFamilyGTMono};
  font-weight: 400;
  font-size: 12px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  top: 12px;
  bottom: 12px;
  color: #cbcbcb;
  ${props =>
    props.error
      ? css`
          color: #e73c41;
        `
      : ''};
`

const CLAIMED = 'Claimed'

const checkSubdomainDebounce = debounce(
  (value, res, rej, setError, tokenCookie, setSubdomainChecked) => {
    if (!value) {
      setError(null)
      return
    }
    axios
      .get(
        `${config.backendUri}/auth/sign-up/tenant/check-subdomain/${value}`,
        {
          headers: {
            Authorization: `Bearer ${tokenCookie}`,
          },
        }
      )
      .then(result => {
        if (!result || !result.data.isAvailable) {
          setError(CLAIMED)
        } else {
          setError(null)
          setSubdomainChecked(true)
        }
      })
      .catch(error => {
        setError(handleAuthNetworkError(error))
      })
  },
  500
)

const ClaimWorkspace = () => {
  const {
    userSession,
    signupStepsMap,
    token,
    setLoading,
    navigateAuth,
  } = useContext(AuthContext)
  const subdomainRegex = /^[0-9A-Za-z]{1}[A-Z-a-z0-9\-]{0,61}[0-9A-Za-z]{1}$/
  const [subdomain, setSubdomain] = useState('')
  const [organizationName, setOrganizationName] = useState('')
  const [teamSize, setTeamSize] = useState(null)
  const teamSizeOptions = [
    {
      label: '1 - 10',
      value: '1-10',
    },
    {
      label: '10 - 100',
      value: '10-100',
    },
    {
      label: '100 - 500',
      value: '100-500',
    },
    {
      label: 'more than 500',
      value: '500+',
    },
  ]
  const [error, setError] = useState(null)
  const [subdomainChecked, setSubdomainChecked] = useState(false)
  useOnEnterEventListener(
    !(63 - subdomain.length < 0 || !subdomainRegex.test(subdomain) || error),
    () => {
      handleSubmit()
    }
  )

  useEffect(() => {
    checkUtmParams()
  }, [])

  useHotjarScript()

  if (!userSession) return null

  const debouncedSubdomainCheck = value => {
    if (!value) return
    return new Promise((res, rej) => {
      setSubdomainChecked(false)
      checkSubdomainDebounce(
        value,
        res,
        rej,
        setError,
        token,
        setSubdomainChecked
      )
    })
  }

  // useEffect(() => {
  //   if (
  //     userSession &&
  //     userSession.email &&
  //     userSession.email.split('@')[1].split('.')[0] !== subdomain
  //   ) {
  //     const initialSubdomain = userSession.email.split('@')[1].split('.')[0]
  //     debouncedSubdomainCheck(initialSubdomain)
  //     setSubdomain(initialSubdomain)
  //   }
  // }, [userSession])

  const handleSubmit = async () => {
    setLoading(true)
    try {
      const result = await axios.post(
        `${config.backendUri}/auth/session`,
        {
          workspaceSubdomain: subdomain,
          companySize: teamSize,
          organizationName,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      if (result.data && result.data.isClaimed) {
        setLoading(false)
        setError('Subdomain is already claimed')
      } else {
        navigateAuth(signupStepsMap.get(result.data.signupStep))
      }
    } catch (error) {
      setLoading(false)
      setError(handleAuthNetworkError(error))
    }
  }

  if (isMobileView()) {
    return (
      <MobileAuthContainer>
        <SEO title="Claim your workspace" />
        <MobileTopBar>Workspace</MobileTopBar>
        <div className="auth-mobile-body">
          <FormBox>
            <Box>
              <AuthTitle>Set up your workspace</AuthTitle>
              <CopySubText
                style={
                  {
                    // marginTop: '16px',
                    // fontSize: '14px',
                    // lineHeight: '22px',
                  }
                }
              >
                This will be used as your main company workspace URL. Get one
                with your actual company name in it.
              </CopySubText>
              <Box style={{ marginTop: '24px' }}>
                <Input
                  placeholder="Organization name"
                  value={organizationName}
                  onChange={event => {
                    setError(null)
                    setOrganizationName(event.target.value)
                  }}
                  style={{
                    width: '100%',
                  }}
                />
              </Box>
              <Box style={{ display: 'flex', marginTop: '10px' }}>
                <Box style={{ position: 'relative' }}>
                  <Input
                    placeholder={userSession.email.split('@')[1].split('.')[0]}
                    style={{ borderRadius: '5px 0px 0px 5px' }}
                    value={subdomain}
                    onChange={event => {
                      setError(null)
                      event.preventDefault()
                      setSubdomain(event.target.value)
                      debouncedSubdomainCheck(event.target.value)
                    }}
                  />
                  {/* <InputLabel
                  error={
                    63 - subdomain.length < 0 ||
                    (subdomain.length && !subdomainRegex.test(subdomain))
                  }
                  title={
                    (63 - subdomain.length < 0 ||
                      (subdomain.length && !subdomainRegex.test(subdomain))) &&
                    'Invalid subdomain'
                  }
                >
                  {63 - subdomain.length}
                </InputLabel> */}
                </Box>
                <PulseDomain>.pulse.so</PulseDomain>
              </Box>
              <Box style={{ marginTop: '10px' }}>
                <BasicSelect
                  placeholder="Team size"
                  options={teamSizeOptions}
                  value={teamSize}
                  onChange={event => {
                    setTeamSize(event.target.value)
                  }}
                />
                {/* <Input
                style={{
                  width: '100%',
                }}
                placeholder="Organization name"
                value={organizationName}
                onChange={event => {
                  setError(null)
                  setOrganizationName(event.target.value)
                }}
              /> */}
              </Box>
              <Box>
                <Button
                  // style={{ width: '376px', height: '48px' }}
                  disabled={
                    // 63 - subdomain.length < 0 ||
                    !subdomainRegex.test(subdomain) ||
                    error ||
                    !subdomainChecked ||
                    !teamSize ||
                    !organizationName
                  }
                  title={
                    (63 - subdomain.length < 0 ||
                      (subdomain.length && !subdomainRegex.test(subdomain))) &&
                    'Invalid subdomain'
                  }
                  onClick={event => {
                    event.preventDefault()
                    handleSubmit()
                  }}
                >
                  {error === CLAIMED ? 'Already Taken' : 'Continue'}
                </Button>
              </Box>
              {error && error !== CLAIMED && (
                <Box style={{ marginTop: '24px' }}>
                  <ErrorBanner>{error}</ErrorBanner>
                </Box>
              )}
            </Box>
          </FormBox>
        </div>
      </MobileAuthContainer>
    )
  }

  return (
    <Layout>
      <SEO title="Claim your workspace" />
      {/* <WorkspaceSetupSidebar step={1} remainingTime="3 min" /> */}
      <Container>
        <AuthSidebar currentStepIndex={2} />
        <FormBox>
          <Box style={{ width: '400px' }}>
            <AuthTitle>Set up your workspace</AuthTitle>
            <CopySubText
              style={{
                marginTop: '16px',
                // fontSize: '14px',
                // lineHeight: '22px',
              }}
            >
              This will be used as your main company workspace URL. Get one with
              your actual company name in it.
            </CopySubText>
            <Box style={{ marginTop: '24px' }}>
              <Input
                placeholder="Organization name"
                value={organizationName}
                onChange={event => {
                  setError(null)
                  setOrganizationName(event.target.value)
                }}
                style={{
                  width: '100%',
                }}
              />
            </Box>
            <Box style={{ display: 'flex', marginTop: '10px' }}>
              <Box style={{ position: 'relative' }}>
                <Input
                  placeholder={userSession.email.split('@')[1].split('.')[0]}
                  style={{ width: '292px', borderRadius: '5px 0px 0px 5px' }}
                  value={subdomain}
                  onChange={event => {
                    setError(null)
                    event.preventDefault()
                    setSubdomain(event.target.value)
                    debouncedSubdomainCheck(event.target.value)
                  }}
                />
                {/* <InputLabel
                  error={
                    63 - subdomain.length < 0 ||
                    (subdomain.length && !subdomainRegex.test(subdomain))
                  }
                  title={
                    (63 - subdomain.length < 0 ||
                      (subdomain.length && !subdomainRegex.test(subdomain))) &&
                    'Invalid subdomain'
                  }
                >
                  {63 - subdomain.length}
                </InputLabel> */}
              </Box>
              <PulseDomain>.pulse.so</PulseDomain>
            </Box>
            <Box style={{ marginTop: '10px' }}>
              <BasicSelect
                placeholder="Team size"
                options={teamSizeOptions}
                value={teamSize}
                onChange={event => {
                  setTeamSize(event.target.value)
                }}
              />
              {/* <Input
                style={{
                  width: '100%',
                }}
                placeholder="Organization name"
                value={organizationName}
                onChange={event => {
                  setError(null)
                  setOrganizationName(event.target.value)
                }}
              /> */}
            </Box>
            <Box style={{ marginTop: '24px' }}>
              <Button
                // style={{ width: '376px', height: '48px' }}
                disabled={
                  // 63 - subdomain.length < 0 ||
                  !subdomainRegex.test(subdomain) ||
                  error ||
                  !subdomainChecked ||
                  !teamSize ||
                  !organizationName
                }
                title={
                  (63 - subdomain.length < 0 ||
                    (subdomain.length && !subdomainRegex.test(subdomain))) &&
                  'Invalid subdomain'
                }
                onClick={event => {
                  event.preventDefault()
                  handleSubmit()
                }}
              >
                {error === CLAIMED ? 'Already Taken' : 'Continue'}
              </Button>
            </Box>
            {error && error !== CLAIMED && (
              <Box style={{ marginTop: '16px' }}>
                <ErrorBanner>{error}</ErrorBanner>
              </Box>
            )}
          </Box>
        </FormBox>
      </Container>
    </Layout>
  )
}

export default ClaimWorkspaceContainer
